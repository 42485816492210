
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&display=swap');

$c_bleu:#80a0d3;
$c_jaune:#f1b152;
$c_vert: #65bba9;
$c_rose:#f27179;
$c_gris:#bcbec0;
$c_grisp:#e6e7e8;
$c_grisfooter:#a7a9ac;



/*désactiver responsive de boostrap*/
.container, .navbar.container  { 
    width: 1200px !important;
	max-width:1200px;
}
.navbar.container{
	margin-top:0;
	margin-bottom:0;
}


@media (min-width: 1200px)
{
	.container {
		width: 1200px !important;
	}
}


@media (min-width: 992px)
{
	.container {
		width: 1200px !important;
	}
}

@media (min-width: 768px){
		.container {
		width: 1200px !important; 
	
	}
}
/*désactiver responsive de boostrap*/

html{
	font-size:16px;
	
	//background:red;
}
p{
	font-size:1em;
}

body{
	font-family: 'Roboto Condensed', sans-serif; 
	font-size:1em;
	//margin-left: auto;
	//margin-right: auto;
	margin:0 auto;
	//width:940px;
	line-height: 1.2;
	background:#bbbbbb;
	
	//margin-top:0!important;
	//padding-top:0!important;
	
	@media(min-width:940px){
		//width:940px;
	}
	
	
}
html body.admin-menu {
//margin-top:0!important;
}

.img-responsive {
width:auto!important;
margin:0 auto;
}


.main-container{
	//width:940px;
	background:white;
	   // margin-top: 122px;
}
.navbar-fixed-top{
	right:0!important;
	left:0!important;
}

iframe body{
	width:100%!important;
}

.front h1.page-header{
	display:none;
}

h1, h2, h3, h4, h5{

margin-top: 0;
    margin-bottom: 0;
	padding:30px 0;

}

.img-responsive{
	width:100%;
}

.container{
	padding-left:0;
	padding-right:0;
}

/*menu pricipal*/
//reset bg a

.navbar-default .navbar-nav > .active > a{

&:hover{
background-color:transparent;
}
	background-color:transparent;
}

body.navbar-is-fixed-top, body.admin-menu.navbar-is-fixed-top{

padding-top:90px;

}
body.admin-menu .navbar-fixed-top{
	//margin-top:29px!important;
	//top:0!important;
}
	.navbar-fixed-top{
	
	right:auto;
	left:auto;

	
	
	}
#navbar{
	font-family: 'Oswald', sans-serif;
	font-weight:normal;
	z-index:10;
    width: 1200px;
	

    margin: 0 auto;

	background-color:black;
	padding:20px;
	border:none;
	border-radius:0;
		border-bottom:1px $c_gris solid;
		
		//position:relative;
	
	nav{
		display:inline-block;
	}
	.cont{
		width:100%;
		text-align: center;
		
	}

	ul.menu{
		text-align: center;
		font-family: 'Oswald', sans-serif;
		
		li.first{
padding:0 15px;
			a {
				text-indent:-10000px;
			width:50px;
			height:50px;
			

			background-color:transparent;

				&:after{
				//content:'';
				//text-indent:10000px;
				background:url(../images/home.png);
				background-repeat: no-repeat;
				background-position: top right;
				 content: " ";
			  position: absolute;
			  top: 0;
			  left: 0;
			  right: 0;
			  bottom: 0;
			  width:50px;
			height:50px;
			padding:10px 15px; 
			  //background: white;
				}
			}



		}
			#dhtml_menu-1178 a{
			//design graphique
				background-color:transparent;
				&:hover, &.active{
					color:$c_bleu;
				}
			
			}
			
				#dhtml_menu-766 a{
			//livres
				background-color:transparent;
				&:hover, &.active{
					color:$c_rose;
				}
			
			}
						#dhtml_menu-1179 a{
			//illustration
				background-color:transparent;
				&:hover, &.active{
					color:$c_jaune;
				}
			
			}
								#dhtml_menu-1180 a{
			//illustration
				background-color:transparent;
				&:hover, &.active{
					color:$c_vert;
				}
			
			}
			
		li{
			    margin: 0 20px;
			a{
				font-size:27px;
				font-weight:normal;
				color:white;
				text-transform:uppercase;
			}
			
			
		
			
			
		}

	}

}



/*image header*/

#block-views-image-header-block{


border-bottom:1px $c_gris solid;
}
/*image header*/

//accueil
.page-node-4{

h2{
	padding:60px 0 20px 0;
}


}

@mixin box-shadow (){
-webkit-box-shadow: 18px 18px 12px 0px rgba(0,0,0,0.1); 
box-shadow: 18px 18px 12px 0px rgba(0,0,0,0.1);
}

@mixin boutonacc (){
	//border:1px green solid;
		    padding: 5px 29px;
    letter-spacing: 1px;
    border-radius: 32px;
    font-size: 49px;
    font-weight: 600;
		color:white;
		//margin:30px 0;
		margin:20px 0 60px 0; 
		display:inline-block;
		  transition: all 0.5s ease;
		
		&:hover{
			background-color:black;
			text-decoration: none;
		}
}

@mixin blocs (){
	//border:1px red solid;
	//margin:30px 0;
	border-bottom:1px solid $c_gris;
	
	


	.block-title
	{
		text-align: center;
		font-size:69px;
		font-weight:300;
	}
	
	 p:first-of-type{
	 color:black;
	 	 	font-size:42px;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight:300;
	    line-height: 1.3em;
		padding:20px 0px;
		margin:0 0;
		//border:1px blue solid;

	}


}

//@extend .ul-style;

/*block accueil*/

#block-block-28{

	@include blocs;

	p{
			a{


			background-color:$c_bleu;
			@include boutonacc;
			@include box-shadow;

			}
	} 


}
#block-block-29{

	@include blocs;
	p{
			a{

			background-color:$c_jaune;
			@include boutonacc;
			@include box-shadow;

			}
		 } 
	 

}
#block-block-30{

	@include blocs;

	p{
			a{

			background-color:$c_vert;
			@include boutonacc;
			@include box-shadow;

			}
	} 
	 

}
#block-block-31{
	@include blocs;
	border-bottom:0!important;


	p{
	 	a{
		    
		background-color:$c_rose; 
		@include boutonacc;
		@include box-shadow;
	
		}
	 } 
	 

}

/*block accueil*/

/*block info*/
#block-block-34{
font-family: 'Oswald', sans-serif;
	p{
		color:$c_gris;
		border-bottom:1px $c_gris solid;
		padding: 20px 0;
		font-size:30px;
		letter-spacing: 0.2em;
		font-weight:300;
		margin:0 0;
		
		
		a{
			color:$c_gris;
			text-decoration:none;
			//vertical-align: text-top;
			padding:0;
			margin:0;
			
			&:hover{
				color:black;
			}
		}
	}
}





/*///*****************///
/*DESIGN GRAPHIQUE*/
/*///*****************///


.page-node-1920{

	h1{
			font-size: 85px;
			color:$c_bleu;
			padding:60px 0 30px 0;
			text-transform: uppercase;
			font-weight:300;
				letter-spacing: 0.07em;

		}
	
		.field-name-body{
		
		margin-bottom:60px;

			p:first-child{
				font-size:41px;
				color:black;
				font-weight:300;
				padding:30px 0;
			}
		p:nth-of-type(2){
		padding:30px 0;
			font-size:53px;
					color:#58595b;
					//background:rgba(242,113,121,0.2);
					text-transform: uppercase;
					font-weight:bold;
					letter-spacing: 0.5em;
				}


		}
	text-align:center;
	
	
	//////////block view
	.view-r-alisations-graphique{
	.views-row{
		border-bottom:1px black solid;
		
		.views-field-field-image{
			img{
				padding:5px 0;
			}
		}
		
		
	}
	
	
	.views-row-last{
		border-bottom:none;
	}
	
	//carre noir
		.views-field-body{
			background-color:black;
			color:white;
			//padding:80px 0;
					height:700px;
			display:flex;
		.field-content{
			margin:auto;
			width:100%;
		}
			
			
			h3{
				color:$c_bleu;
				font-size:35px;
				font-weight:bold;
				letter-spacing: 0.2em;
				padding:20px 0 60px 0;
			}
			img{
				padding-top:40px;
			}
			p{
				font-size:41px;
				color:#d1d3d4;
			}
		
			
		}
		//carre noir
		
		//titre realisation
		.views-field-views-conditional{
			font-size:41px; /*31pt*/
			
			color:#58595b;
			font-weight:300;
			padding:40px 0 0 0; 
		}
	}
	
	
	///////////block view

}



	


/**/


/*///*****************///
/*DESIGN GRAPHIQUE*/
/*///*****************///








/*footer*/

.footer{
border:none;
color:white;
background-color:$c_grisfooter;
margin:0 auto;
padding-top: 0;
    padding-bottom: 0;

	#block-block-32{
	padding:20px 0 20px 0;
			p{
			line-height:100%;
			//border:1px pink solid;
			font-size:54px;
			font-weight:bold;
			margin:0 auto;
			padding:20px 0 20px 0;
			//display:inline-block;
			
			a{
				color:white;
				//line-height:54px;
				
				letter-spacing: .03em;
				&:hover{
				
					text-decoration: none;
				}
			}
		}
		
		
		
		p:nth-of-type(3){
		
			font-weight:normal;
			//margin:40px 0;
			font-size:38px;
			line-height:38px;
			//border:1px yellow solid;
			
		}
		p:last-child{
			display:block;
			a{
				display:inline-block;
	text-indent:-10000px;
	
width:65px;
height:65px;
background-color:transparent;

	background:url(../images/fcbk.png);
	background-repeat: no-repeat;
	background-position: 50% center;
	
	
	&:after{
	//content:'';
	//text-indent:10000px;);
	//background:url(../images/fcbk.png);
	//-repeat: no-repeat;
	//background-position: middle center;
	 //content: " ";
  //position: absolute;
  //top: 0;
  //: 0;
  //right: 0;
//bottom: 0;
  //background: white;
	}
			}
			
		
		
	}
		
	}
/////////

	#block-block-35{
	padding:55px 0 75px 0; 
			p{
			line-height:100%;
			//border:1px pink solid;
			font-size:54px;
			font-weight:bold;
			margin:0 auto;
			padding:10px 0 10px 0;
			//display:inline-block;
			
			a{
				color:white;
				//line-height:54px;
				
				letter-spacing: .03em;
				&:hover{
				
					text-decoration: none;
				}
			}
		}
		
		
		
		p:nth-of-type(2){
		
			//font-weight:normal;
			//color:blue;
			padding:15px 0;
			font-size:45px;
			line-height:38px;
			font-weight:bold;
			//border:1px yellow solid;
			letter-spacing: .05em;
			
		}
			p:nth-of-type(3){
		
a{

			//font-weight:normal;
			//color:blue;
			//margin:40px 0;
			font-size:45px;
			line-height:38px;
			font-weight:bold;
			//border:1px yellow solid;
			letter-spacing: .05em;

}		
			
		}
		/*p:last-child{
			display:block;
			a{
				display:inline-block;
	text-indent:-10000px;
	
width:65px;
height:65px;
background-color:transparent;

	background:url(../images/fcbk.png);
	background-repeat: no-repeat;
	background-position: 50% center;
	
	
	&:after{
	//content:'';
	//text-indent:10000px;);
	//background:url(../images/fcbk.png);
	//-repeat: no-repeat;
	//background-position: middle center;
	 //content: " ";
  //position: absolute;
  //top: 0;
  //: 0;
  //right: 0;
//bottom: 0;
  //background: white;
	}
			}*/
			
		
		
	}

	#block-block-33{
		border-top:1px white solid;
		
			p{
				font-size:26px;
				color:black;
				padding:20px 0;
				margin: 0 auto;
				letter-spacing: .05em;
		}
	}
		
	}//footer
	
	/////


	









/*editeur CSS*/
.imggauche
{
float:left;	
}

.imgdroite
{
float:right;	
}


////////////////////****///////////
/************MAILCHIMP*///////////////
////////////////////****///////////
#colorbox{
	margin:0 auto;
	left:0!important;
	right:0;
}

#block-block-24{
	position: absolute;
    top: 440px;
     left: 50%;
  transform: translateX(-50%);
}
#cboxWrapper{
//background:#e6e7e8!important; 
	header h2{
		display:none;
	
	}
	#mc_embed_signup_scroll h3{
	text-align:center;	
		text-transform:none!important;
		margin-top: 87px;
		letter-spacing: 0.02em;
		padding:0 30px;
		margin-bottom:30px;
		
	}
	
	.mc-field-group{
		text-align:center;
	}
	#mc-embedded-subscribe{
		display: inline-block;
		width:25%;
		color:#58595b;
		background:none;
		border-radius: none;
		border:1px #58595b solid;
		
	}
	#mce-EMAIL{
		display: inline-block!important;
		border:1px #58595b solid;
		margin:0;
	}
	#mc_embed_signup{
		background:#e6e7e8!important;
	}
	.inputemail{
		width:500px;
		margin:0 auto;
		text-align:center; 
			
		
	}
		
}
#colorbox #cboxWrapper{
		background:#e6e7e8!important;
		#cboxContent{
		background:#e6e7e8!important;
	}

	.mc-field-group{
		text-align:center;
	}
	#mc-embedded-subscribe{
		display: inline-block;
		width:25%;
		color:#58595b;
		background:none;
		border-radius: none;
		border:1px #58595b solid;
		
	}
	#mce-EMAIL{
		display: inline-block!important;
		border:1px #58595b solid;
		margin:0;
	}
	#mc_embed_signup{
		background:#e6e7e8!important;
	}
	.inputemail{
		width:500px;
		margin:0 auto;
		text-align:center; 
			
		
	}
	
} 

////////////////////****///////////
/************MAILCHIMP*///////////////
////////////////////****///////////



////////////////////****///////////
/************SHADOWBOX*///////////////
////////////////////****///////////
#sb-body{
	background:transparent;
	
}


#sb-loading #sb-loading-inner span{
text-indent:-10000px;
}

.page-node-1923 #sb-overlay{
	background-color:black!important;
}
.page-node-1387{
#sb-wrapper-inner{
	border:none;
	box-shadow: 10px 10px 20px -9px #4b4b4b;
		-webkit-box-shadow: 10px 10px 20px -9px #4b4b4b;
//box-shadow: 6px 6px 10px -5px #000000;
}

 #sb-overlay{
	background-color:#a7a9ac!important;
}
#sb-body-inner{
//overflow: hidden;
 img{
 	//border: 1px solid #303030;
	//width: 100%;
    //height: auto;
	//object-fit: cover;
	//vertical-align: middle;
	//display:block;
	//line-height: 0;


	
	
 }

}

}
	#sb-info-inner, #sb-info
		{
			height:100px!important;	
			margin:0 auto!important;
		}




#sb-nav
{
	padding:20px 0;
	height:70px!important;
	float:none!important;
	width:100%!important;
	margin:20px auto 0 auto!important;
	
	
	#sb-info-inner-inner
	{
		//height:100px!important;	 
	}
	a
	{
		margin-left:130px;
		  
		
	}
	#sb-nav-next
	{
		background-image:url("../images/sb-next.png")!important;
		width:40px;
		height:40px;	
	}
		#sb-nav-previous
	{
		background-image:url("../images/sb-prev.png")!important;
		width:40px;
		height:40px;
		padding:0!important;	
		margin-left:0!important;
	}
		#sb-nav-close
	{
		background-image:url("../images/sb-close.png")!important;
		width:40px;
		height:40px;	
	}
	
	

}



////////////////////****///////////
/************SHADOWBOX*///////////////
////////////////////****///////////






////////////////////****///////////
/************page illustration*///////////////
////////////////////****///////////
.page-node-1922{


	h1{
		font-size: 85px;
		color:$c_jaune;
		padding:60px 0 30px 0;
		text-transform: uppercase;
		font-weight:300;
		    letter-spacing: 0.07em;
		
	}
	.field-name-body{
		
		margin-bottom:60px;
		
		p:first-child{
			font-size:41px;
			color:black;
			font-weight:300;
			padding:30px 0;
		}
	p:nth-of-type(2){
	padding:30px 0 0 0;
		font-size:53px;
				color:#58595b;
				//background:rgba(242,113,121,0.2);
				text-transform: uppercase;
				font-weight:bold;
				letter-spacing: 0.5em;
				
				
			}
			p:nth-of-type(3){
					padding:20px 0 30px 0;
					font-weight:300;
					font-size:25px;
					color:black;
					letter-spacing: 0.05em;
					
					
				}
			
			
	}
	text-align:center;
	
	
	
	
		.views-field-body{
	//padding: 187px 0;
			background-color:black;
			color:white;
			//padding:80px 0;
			//margin-bottom:50px;
			height:700px;
			display:flex;
		.field-content{
			margin:auto;
			width:100%;
		}
			
			h3{
				color:$c_jaune;
				font-size:35px;
				font-weight:bold;
				letter-spacing: 0.2em;
				padding:20px 0 60px 0;
			}
			img{
				padding-top:40px;
			}
			p{
				font-size:41px;
				color:#d1d3d4;
			}
		
			
		}
		
		.views-row-last .views-field-field-illustration {
			border-bottom:none!important;
		
		}
		.views-row {
		
			.views-field-field-illustration{
				padding:100px 0;
				border-bottom:1px black solid; 
			}
		
		}
	
	
	
}



////////////////////****///////////
/************page illustration*///////////////
////////////////////****///////////







////////////////////****///////////
/************page photo paysage*///////////////
////////////////////****///////////
.page-node-1923{


	h1{
		font-size: 85px;
		color:$c_vert;
		padding:60px 0 30px 0;
		text-transform: uppercase;
		font-weight:300;
		    letter-spacing: 0.07em;
		
	}
	.field-name-body{
		
		margin-bottom:60px;
		
		p:first-child{
			font-size:41px;
			color:black;
			font-weight:300;
			padding:30px 0;
		}
	p:nth-of-type(2){
	padding:30px 0 0 0;
		font-size:53px;
				color:#58595b;
				//background:rgba(242,113,121,0.2);
				text-transform: uppercase;
				font-weight:bold;
				letter-spacing: 0.5em;
				
				
			}
			p:nth-of-type(3){
					padding:20px 0 30px 0;
					font-weight:300;
					font-size:25px;
					color:back;
					letter-spacing: 0.05em;
				}
			
			
	}
	text-align:center;
	
	
		#block-views-photo-paysage-block-1{
		//margin-top:80px;
	}


	#block-views-photo-paysage-block-1, #block-views-photo-paysage-block-3{
		h2{
			background:$c_grisp;
			text-transform: uppercase;
			letter-spacing: 0.2em;
			color:#808285;
			border-top:1px #808285 solid;
			border-bottom:1px #808285 solid;
			font-size:38px;
			padding: 25px 0 5px 0;
			//text-transform: uppercase;
			
			&:after{
			
			    background: url(../images/arrow_gris_bas.png);
    background-repeat: no-repeat;
    background-position: 100% center;
    content: " ";
    /* position: relative; */
    /* top: 0; */
    /* left: 0; */
    /* right: 50%; */
    bottom: 0;
    /* margin-right: -13px; */
  //  z-index: 100000000;
    display: block;
    width: 28px;
    height: 20px;
    /* text-align: center; */
    /* float: none; */
    margin: 15px auto;
			}
			}
		
		ul{
			list-style-type: none;
			padding:0;
			margin-bottom:0;
			li{
				//padding:0;
			}
		}
		
		
		
	
		.views-row{
			//padding:60px 0;
			//border-bottom:1px #cbccce solid;
			
			
			.views-field-field-image-couverture{
				margin:0 auto;
				text-align: center;
				img{
				display:inline-block;
					max-width:400px;
					height:auto;	
					//border:1px $c_gris solid;
				-webkit-box-shadow: 24px 15px 30px 0px rgba(0,0,0,0.26);
-moz-box-shadow: 24px 15px 30px 0px rgba(0,0,0,0.26);
//box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.26);




box-shadow: 24px 15px 30px 0px rgba(0,0,0,0.26);



				}
			}
		}
		.views-row-last{border-bottom:none;}
		.views-field-field-titre-2{
			font-size:24px;
			//color:#939598;
			//letter-spacing:0.2em;
			span{
				color:#5a87c6;
				
			
			}
			//margin-bottom:60px;
			line-height: 0.8em;
			color:#d1d3d4;
			
		}
		
		.views-field-name-2, .views-field-name{
			font-size:34px;
			color:#d1d3d4;
			background-color:black;
			text-transform: uppercase;
			font-weight:normal;
			padding-top:40px;
			padding-bottom:20px;
			letter-spacing: 0.05em;
			//letter-spacing:0.2em;
				span{
			
				
				&:before{
				margin-bottom:10px;
					content:"Collection";
					display:block;
					color:$c_vert;
					text-transform: none;
					font-size:38px;
					font-weight:normal;
					letter-spacing: 0.05em;
				}
			}
		}
		
	
	.views-field-nothing{
	
	/*position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;*/

//text-align: center;

	display:block; 
	
	
	//margin-top:40px;
	
	
		//background-color:white;	
		a{
		
			color:white;//border:1px green solid;
		    padding: 5px 29px;
    letter-spacing: 1px;
    border-radius: 32px;
    font-size: 43px;
    font-weight: 400;
		border:2px white solid;
		//margin:30px 0;
		//margin:20px 0 60px 0; 
		//display:inline-block;
		  transition: all 0.5s ease;
		  
		  
		  position: absolute;
//left: 50%;
-webkit-transform: translateX(-50%);
transform: translateX(-50%);
margin-top:40px;
		
		&:hover{
			background-color:black;
			text-decoration: none;
		}
		//@include box-shadow;	
		}
	
	}
	
	}
	
	#block-views-photo-paysage-block-2{
		display:none;
	}
	
	
	#sb-wrapper-inner{
	border:none;
}
	
}



////////////////////****///////////
/************page photo paysage*///////////////
////////////////////****///////////









////////////////////****///////////
/************page livre dimages*///////////////
////////////////////****///////////

.page-header{
	border-bottom:none;
	margin:0 0;
}
.page-node-1387{
	
	h1{
		font-size: 85px;
		color:$c_rose;
		padding:60px 0 30px 0;
		text-transform: uppercase;
		font-weight:300;
		    letter-spacing: 0.07em;
		
	}
	.field-name-body{
		
		p:first-child{
			font-size:41px;
			color:black;
			font-weight:300;
			padding:30px 0;
		}
	p:nth-of-type(2){
	padding:30px 0;
		font-size:53px;
				color:#58595b;
				//background:rgba(242,113,121,0.2);
				text-transform: uppercase;
				font-weight:bold;
				letter-spacing: 0.5em;
			}
			
			
	}
text-align:center;

	#block-views-livres-virtuels-block-1{
		margin-top:80px;
	}


	#block-views-livres-virtuels-block-1, #block-views-livres-virtuels-block-3{
		h2{
			background:$c_grisp;
			text-transform: uppercase;
			letter-spacing: 0.2em;
			color:#808285;
			border-top:1px #808285 solid;
			border-bottom:1px #808285 solid;
			font-size:38px;
			padding: 25px 0 5px 0;
			//text-transform: uppercase;
			
			&:after{
			
			    background: url(../images/arrow_gris_bas.png);
    background-repeat: no-repeat;
    background-position: 100% center;
    content: " ";
    /* position: relative; */
    /* top: 0; */
    /* left: 0; */
    /* right: 50%; */
    bottom: 0;
    /* margin-right: -13px; */
  //  z-index: 100000000;
    display: block;
    width: 28px;
    height: 20px;
    /* text-align: center; */
    /* float: none; */
    margin: 15px auto;
			}
			}
		
		ul{
			list-style-type: none;
			padding:0;
			li{
				//padding:0;
			}
		}
		
		
		
	
		.views-row{
			padding:60px 0;
			border-bottom:1px #cbccce solid;
			
			
			.views-field-field-image-couverture{
				margin:0 auto;
				text-align: center;
				img{
				display:inline-block;
					max-width:400px;
					height:auto;	
					//border:1px $c_gris solid;
				-webkit-box-shadow: 24px 15px 30px 0px rgba(0,0,0,0.26);
-moz-box-shadow: 24px 15px 30px 0px rgba(0,0,0,0.26);
//box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.26);




box-shadow: 24px 15px 30px 0px rgba(0,0,0,0.26);



				}
			}
		}
		.views-row-last{border-bottom:none;}
		.views-field-field-titre-2{
			font-size:24px;
			color:#939598;
			//letter-spacing:0.2em;
			span{
				color:#5a87c6;
			}
			//margin-bottom:60px;
			line-height: 0.8em;
		}
		
		.views-field-name-2{
			font-size:34px;
			color:black;
			text-transform: uppercase;
			font-weight:300;
			margin-top:40px;
			margin-bottom:5px;
			letter-spacing: 0.05em;
			//letter-spacing:0.2em;
		}
		
	}
	.views-field-nothing{
	margin-top:40px;
	
	
		//background-color:white;	
		a{
			color:$c_gris;//border:1px green solid;
		    padding: 5px 29px;
    letter-spacing: 1px;
    border-radius: 32px;
    font-size: 43px;
    font-weight: 400;
		border:1px $c_gris solid;
		//margin:30px 0;
		//margin:20px 0 60px 0; 
		display:inline-block;
		  transition: all 0.5s ease;
		
		&:hover{
			background-color:black;
			text-decoration: none;
		}
		@include box-shadow;	
		}
	
	}
	
	
	
	#block-views-livres-virtuels-block-2{
		display:none;
	}
	
}



/*Nos livres d'images*/


////////////////////****///////////
/************page livre dimages*///////////////
////////////////////****///////////


